/* eslint-disable react-hooks/exhaustive-deps */
import { getThingFeatureCombinations, LastThingEvent, Thing, ThingType } from '@eagle/core-data-types';
import { VideoSegmentStatus } from '@eagle/data-function-types';
import { CacheDataTypes, DateTimeRangeSelect, FlexBox, getLastThingEvent, LAST_CONTACT_EXCLUDED_EVENTS, T_ONE, Undefinable, useAuthenticated, useBoolFlag, useFetchAllCache, usePromise, useSmallScreen } from '@eagle/react-common';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Badge, BottomNavigation, BottomNavigationAction, Box, Container, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterType, useVideoFilters } from './camera-content';
import { ControllerProps } from './camera-controller.props';
import { DetailView } from './camera-view';

export const CameraController: FC<ControllerProps> = ({
  actions,
  dateOptions,
  dateRange,
  disableBack,
  disableForward,
  forwardLabel,
  handleBack,
  handleForward,
  isLoading,
  loadData,
  onDateRangeChanged,
  pageIcon,
  renderPageContent,
  renderPageTitle,
  renderRequestButton,
  view: DataView = DetailView,
  ...props
}): JSX.Element => {
  const { t } = useTranslation(['common', 'terms', 'track']);
  const { restClient, userInfo } = useAuthenticated();
  const data = usePromise<Undefinable<Thing>>(() => loadData(), [loadData, userInfo.accountId]);
  const smallScreen = useSmallScreen();

  const [thing] = data;
  const [requestOpen, setRequestOpen] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { filters, setFilters } = useVideoFilters();
  const useDateTimeRangeSelectComponent = useBoolFlag('track-camera-content-datetime-picker-component-temporary');

  const lastThingData = usePromise<Undefinable<LastThingEvent>>(
    () => {
      if (!thing) return Promise.resolve(undefined);
      return getLastThingEvent(
        restClient,
        thing._id,
        {
          filter: {
            eventTypeId: {
              $nin: LAST_CONTACT_EXCLUDED_EVENTS,
            },
          },
        }
      );
    },
    [restClient, thing]
  );

  const thingTypesCache = useFetchAllCache(CacheDataTypes.THING_TYPE);
  const [thingType] = usePromise(
    async () => {
      if (!thing?.thingTypeId) return Promise.resolve(undefined);
      const thingTypeCacheData = await thingTypesCache.one<ThingType>(thing?.thingTypeId);
      const cameraFeature = thingTypeCacheData?.features.find((feature) => feature.featureId === 'camera');
      const thingCameraFeatures = cameraFeature ? getThingFeatureCombinations(cameraFeature) : [];
      const updatedFilters = [...Object.values(VideoSegmentStatus).map((status) => ({ status })), ...thingCameraFeatures.map((feature) => ({ feature }))];
      setFilters(updatedFilters as unknown as Record<FilterType, string>[]);
      return thingTypeCacheData;
    },
    [thingTypesCache, thing]
  );

  const cameraFeature = thingType?.features.find((feature) => feature.featureId === 'camera');
  const thingCameraFeatures = cameraFeature ? getThingFeatureCombinations(cameraFeature) : [];

  const handleDateClose = (): void => setDateOpen(false);
  const handleFilterClose = (): void => setFiltersOpen(false);
  const handleModalClose = (): void => setRequestOpen(false);
  const handleModalOpen = (): void => setRequestOpen(true);

  const allFiltersLength = Object.values(VideoSegmentStatus).length + thingCameraFeatures.length;
  const badgeInvisibility = filters.length === allFiltersLength || !filters.length;

  const actionsOrMobileActions = smallScreen && actions
    ? [
      ...(useDateTimeRangeSelectComponent
        ? []
        : [{
          disabled: isLoading,
          icon: <DateRangeIcon />,
          label: t('common:common.labels.date-range'),
          onClick: () => setDateOpen(true),
        }]
      ),
      {
        disabled: isLoading,
        icon: <Badge component='div' badgeContent={filters.length} invisible={badgeInvisibility} variant="dot" color="primary"><FilterAltIcon /></Badge>,
        label: t('common:component.filter.heading'),
        onClick: () => setFiltersOpen(true),
      },
      ...actions,
      {
        icon: <PermMediaIcon />,
        label: t('track:page.camera-content.action.get-media'),
        onClick: handleModalOpen,
      },
    ]
    : actions;

  useEffect(handleDateClose, [isLoading]);

  return (
    <FlexBox data-testid={props['data-testid']} sx={{ overflowY: 'auto' }} flexDirection="column">
      <Container sx={{ display: 'flex', flexGrow: 1, mb: '3rem', py: 1, px: smallScreen ? 0 : 'inherit' }}>
        <DataView
          actions={actionsOrMobileActions}
          data={data}
          dateOpen={dateOpen}
          dateOptions={dateOptions}
          dateRange={dateRange}
          disableBack={disableBack}
          disableForward={disableForward}
          filtersOpen={filtersOpen}
          forwardLabel={forwardLabel}
          handleBack={handleBack}
          handleDateClose={handleDateClose}
          handleFilterClose={handleFilterClose}
          handleForward={handleForward}
          handleModalClose={handleModalClose}
          isLoading={isLoading}
          lastThingData={lastThingData}
          onDateRangeChanged={onDateRangeChanged}
          pageIcon={pageIcon}
          renderPageContent={renderPageContent}
          renderPageTitle={renderPageTitle}
          renderRequestButton={renderRequestButton}
          requestOpen={requestOpen}
          thingType={thingType}
        />
      </Container>
      {smallScreen
        && actionsOrMobileActions
        && <Box display="flex" sx={{ alignSelf: 'stretch' }}>
          <BottomNavigation
            showLabels
            sx={{
              bottom: 0,
              boxShadow: (theme) => theme.shadows[4],
              height: 'min-content',
              justifyContent: 'space-around',
              position: 'fixed',
              width: '100vw',
              zIndex: 1,
            }}
          >
            {useDateTimeRangeSelectComponent
            && <DateTimeRangeSelect
              defaultValue={{
                startDate: DateTime.now().minus({ hour: 1 }),
                endDate: DateTime.now(),
              }}
              maxDateRange={1}
              onDateChange={(startDate, endDate) => {
                onDateRangeChanged(startDate.toJSDate(), endDate.toJSDate());
              }}
              quickSelectOptions={[
                {
                  label: t('common:component.date-time-range.labels.last-hour', { count: T_ONE }),
                  value: DateTime.now().minus({ hours: 1 }),
                },
                {
                  label: t('common:component.date-time-range.labels.last-hour', { count: 4 }),
                  value: DateTime.now().minus({ hours: 4 }),
                },
                {
                  label: t('common:component.date-time-range.labels.last-hour', { count: 8 }),
                  value: DateTime.now().minus({ hours: 8 }),
                },
              ]}
              renderSmallView={({handleClickButton}) => {
                return (
                  <BottomNavigationAction
                    key={`${0}-bottom-nav`}
                    disabled={isLoading}
                    icon={<DateRangeIcon />}
                    label={<Typography variant="caption">{t('common:common.labels.date-range')}</Typography>}
                    onClick={handleClickButton}
                    sx={{ px: 0 }}
                    value={0}
                    showLabel
                  />
                );
              }}
              smallScreen={smallScreen}
              value={dateRange
                ? {
                  startDate: dateRange.startTime,
                  endDate: dateRange.endTime,
                }
                : undefined
              }
            />
            }
            {actionsOrMobileActions.map(({ disabled, icon, label, onClick }, i) => (
              <BottomNavigationAction
                key={`${i}-bottom-nav`}
                disabled={disabled}
                icon={icon}
                label={<Typography variant="caption">{label}</Typography>}
                onClick={onClick}
                sx={{ px: 0 }}
                value={i + (useDateTimeRangeSelectComponent ? 1 : 0)}
              />
            ))}
          </BottomNavigation>
        </Box>
      }
    </FlexBox>
  );
};

export default CameraController;
