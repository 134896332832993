/* eslint-disable react-hooks/exhaustive-deps */
import { Thing, ThingType } from '@eagle/core-data-types';
import { CacheDataTypes, DateRange, FeatureIcons, FetchOneOfAll, formatShortDate, FormatTimestamp, isMatchingDay, ListSearchProvider, MiddleSpinner, PortalFeatureIcons, SEGMENT_MINUTES, Undefinable, useAuthenticated, useDynamicModule, useFetchOneCache, useHasAuthorization, useListSearch } from '@eagle/react-common';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { dateOptions } from './camera-content-dates';
import { CameraDateOptions } from './camera-content.types';
import { CameraController } from './camera-controller';
import { RequestAvailableMedia } from './request-media/request-available-media';
import { VideoSegmentContainer } from './video-segment';

interface Props {
  'data-testid'?: string;
  endTime?: DateTime;
  startTime?: DateTime;
  thing?: Thing;
}

interface LocationState {
  endTime: Date;
  startTime: Date;
}

const MEDIA_REQUESTOR = ['media-requester'] as const;

export type FilterType = 'feature' | 'status';

interface Context {
  filters: Record<FilterType, string>[];
  setFilters: (filters: Record<FilterType, string>[]) => void;
}

const context = createContext<Undefinable<Context>>(undefined);

const FiltersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [filters, setFilters] = useState<Record<FilterType, string>[]>([]);
  return (
    <context.Provider
      value={{
        filters,
        setFilters,
      }}>
      {children}
    </context.Provider>
  );
};

export const useVideoFilters = (): Context => {
  const data = useContext(context);
  if (!data) throw new Error('Missing VideoFilterProvider in tree above useVideoFilters');
  return data;
};

export const InternalCameraContent: FC<Props> = ({
  endTime = DateTime.now(),
  startTime = DateTime.now().minus({ hours: 1 }),
  thing = undefined,
  ...props
}): JSX.Element => {
  const { state } = useLocation();
  const { t } = useTranslation(['common', 'track']);
  const things = useFetchOneCache(CacheDataTypes.THING);
  const { id } = useParams();
  const { userInfo } = useAuthenticated();
  const [dateRange, setDateRange] = useState<CameraDateOptions>({
    startTime: state ? DateTime.fromJSDate((state as LocationState).startTime) : startTime,
    endTime: state ? DateTime.fromJSDate((state as LocationState).endTime) : endTime,
    scrollOptions: {
      start: true,
      end: false,
    },
  });
  const [disableForward, setDisableForward] = useState(false);
  const [disableBack, setDisableBack] = useState(false);
  const [disableAutoScroll, setDisableAutoScroll] = useState(false);
  const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading,
    setIsLoading,
  } = useListSearch();
  const { hasAuthorization } = useHasAuthorization();
  const requestPermissions = hasAuthorization(MEDIA_REQUESTOR);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const dateOption = dateOptions();

  const nextDayString = (date: DateRange): string => {
    const dateDiff = date.endTime.diff(date.startTime).toObject();
    const futureEndTime = date.endTime.plus({ 'milliseconds': dateDiff?.milliseconds });
    if (!isMatchingDay(futureEndTime, date.endTime)) {
      return formatShortDate(futureEndTime.minus({ 'millisecond': 1 }));
    }
    return '';
  };

  const onQueryChange = (startTime: DateTime, endTime: DateTime, scroll: boolean, scrollStart = true, disableAutoScroll = true): void => {
    setIsLoading(true);
    setDisableAutoScroll(disableAutoScroll);
    setTimeout(() => {
      setDateRange(scroll
        ? { startTime, endTime, scrollOptions: { start: scrollStart, end: !scrollStart } }
        : { startTime, endTime });
    }, 10);
  };

  const handleBack = (date?: DateRange, minDateTime?: DateTime, mobileLogic?: boolean): void => {
    if (!date) return;
    const dateDiff = date.endTime.diff(date.startTime).toObject().milliseconds;
    const endBackwardValue = mobileLogic ? date.endTime : date.startTime;
    const startBackwardValue = mobileLogic ? date.startTime.minus({ minutes: SEGMENT_MINUTES * 2 }) : endBackwardValue.minus({ 'milliseconds': dateDiff });
    const backwardCheck = minDateTime && ((mobileLogic ? endBackwardValue.minus({ minutes: SEGMENT_MINUTES * 2 }) : endBackwardValue.minus({ 'milliseconds': dateDiff })) < minDateTime);

    if (backwardCheck) {
      setDisableBack(true);
    }
    else {
      setDisableBack(false);
    }

    if (!mobileLogic) setDisableForward(false);
    onQueryChange(startBackwardValue, endBackwardValue, true, false);
  };

  const handleForward = (date?: DateRange, mobileLogic?: boolean): void => {
    if (!date) return;
    const dateDiff = date.endTime.diff(date.startTime).toObject().milliseconds;
    const startForwardValue = mobileLogic ? date.startTime : date.endTime;
    const lessThanAddSegment = date.endTime > DateTime.now().endOf('day').minus({ minutes: SEGMENT_MINUTES * 2 });
    const endForwardValue = lessThanAddSegment ? DateTime.now().endOf('day') : (mobileLogic ? date.endTime.plus({ minutes: SEGMENT_MINUTES * 2 }) : startForwardValue.plus({ milliseconds: dateDiff }));

    setDisableBack(false);
    onQueryChange(startForwardValue, endForwardValue, true);
  };

  const bannerOpen = (data: string): void => {
    enqueueSnackbar(data, { variant: 'info' });
  };

  const handleGridLoaded = (): void => {
    setIsLoading(false);
    setLastRefresh(new Date());
  };

  const renderPageContent = useCallback((
    thing: Thing,
    dateRange: CameraDateOptions,
  ): JSX.Element => {
    return (
      <FetchOneOfAll
        dataType={CacheDataTypes.THING_TYPE}
        id={thing.thingTypeId}
        renderFactory={(thingType: ThingType) => (
          <VideoSegmentContainer
            bannerOpen={bannerOpen}
            dateRange={dateRange}
            disableAutoScroll={disableAutoScroll}
            disableBack={disableBack}
            disableForward={disableForward}
            handleBack={handleBack}
            handleForward={handleForward}
            handleGridLoaded={handleGridLoaded}
            refresh={refresh}
            thing={thing}
            thingType={thingType}
          />
        )}
      />
    );
  }, [disableAutoScroll, lastRefresh, refresh]);

  const renderRequestButton = (data: Thing): JSX.Element => {
    if (!requestPermissions) return <></>;
    return (
      <RequestAvailableMedia
        bannerOpen={bannerOpen}
        thing={data}
        dateRange={{ startDate: dateRange.startTime.toJSDate(), endDate: dateRange.endTime.toJSDate() }}
      />
    );
  };

  const actions = [
    {
      icon: <RefreshIcon />,
      info: <Typography data-chromatic="ignore" variant="caption" sx={{ mt: 1, width: '200px' }}>{`${t('common:page.alert-list.last-updated.labels')} `}<FormatTimestamp value={lastRefresh} format="relative" /></Typography>,
      label: t('common:common.action.refresh'),
      testId: 'action-button-refresh',
      onClick: () => {
        setRefresh((prev) => !prev);
        onQueryChange(dateRange.startTime, dateRange.endTime, false);
      },
    },
  ];

  const loadData = useCallback(
    () => {
      if (!thing) return things.one<Thing>(id);
      return Promise.resolve(thing);
    },
    [id, refresh, userInfo.accountId]
  );

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <CameraController
      actions={actions}
      data-testid={props['data-testid']}
      dateOptions={dateOption}
      dateRange={dateRange}
      disableBack={disableBack}
      disableForward={disableForward}
      forwardLabel={nextDayString(dateRange)}
      isLoading={isLoading}
      loadData={loadData}
      handleForward={handleForward}
      handleBack={handleBack}
      onDateRangeChanged={(startFromDate, endToDate) => onQueryChange(DateTime.fromJSDate(startFromDate), DateTime.fromJSDate(endToDate), true)}
      pageIcon={module?.CameraContentIcon && <module.CameraContentIcon />}
      renderPageContent={renderPageContent}
      renderPageTitle={() => t('track:page.camera-content.title')}
      renderRequestButton={renderRequestButton}
    />
  );
};

export const CameraContent: FC<Props> = (props: Props): JSX.Element => {
  return (
    <FiltersProvider>
      <ListSearchProvider dataKey="camera-content">
        <InternalCameraContent {...props} />
      </ListSearchProvider>
    </FiltersProvider>
  );
};

export default CameraContent;
