import { createHourSelectOption, CustomRange, DateTimeRange, DateTimeRangePicker, DateTimeRangePickerProvider, DateTimeRangeSelect, FlexBox, RangeControl, RangeSelect, RangeSelectValues, T_ONE, useBoolFlag, useMaxScreen, useSmallScreen } from '@eagle/react-common';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Grid, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CameraDateRangeProps } from './camera-date-range.props';

const CameraDateRange: FC<CameraDateRangeProps> = ({
  dateOptions,
  dateRange,
  disableBack,
  disableForward,
  forwardLabel,
  handleBack,
  handleForward,
  isLoading,
  minDateTime,
  maxDateTime,
  onDateRangeChanged,
}) => {
  const { t } = useTranslation(['common', 'track']);
  const smallScreen = useSmallScreen();
  const mediumScreen = useMaxScreen('md');
  const { state } = useLocation();
  const [rangeSelection, setRangeSelection] = useState<RangeSelectValues['key']>(state ? RangeSelectValues.PICK_CUSTOM_RANGE.key : RangeSelectValues.LAST_8_HOURS.key);
  const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState(false);
  const isSourcedFromAlertDetails = rangeSelection === RangeSelectValues.PICK_CUSTOM_RANGE.key && state;
  const [savedRange, setSavedRange] = useState<CustomRange>({
    startDate: undefined,
    endDate: DateTime.now().set({ hour: 23, minute: 59, second: 59 }),
  });
  const useDateTimeRangeSelectComponent = useBoolFlag('track-camera-content-datetime-picker-component-temporary');

  useEffect(() => {
    if (!isSourcedFromAlertDetails) return;
    setSavedRange({
      startDate: dateRange.startTime,
      endDate: dateRange.endTime,
    });
  }, [dateRange, isSourcedFromAlertDetails]);

  const rangeSelectOptions = [
    createHourSelectOption(RangeSelectValues.LAST_HOUR),
    createHourSelectOption(RangeSelectValues.LAST_4_HOURS),
    createHourSelectOption(RangeSelectValues.LAST_8_HOURS),
  ];

  if (smallScreen) {
    return (
      <Stack direction="column" justifyContent="flex-end" sx={{ width: 1, my: 0 }} spacing={1}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Button
              disabled={isLoading || disableBack}
              onClick={() => handleBack(dateRange, minDateTime)}
              startIcon={<ChevronLeft />}
              sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'flex-start' }}
            />
          </Grid>
          <Grid item xs={8}>
            <DateTimeRange
              dateOptions={dateOptions}
              defaultEnd={dateRange.endTime}
              defaultStart={dateRange.startTime}
              disabled={isLoading}
              maxDateTime={maxDateTime}
              minDateTime={minDateTime}
              onChange={onDateRangeChanged}
              onlyIcon={false}
              pickerWidth="100%"
              placeholderText={t('common:common.labels.date-range')}
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={isLoading || disableForward}
              onClick={() => handleForward(dateRange)}
              endIcon={<ChevronRight />}
              sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'flex-end' }}
            >
              {forwardLabel}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    );
  }

  return (
    <Stack direction={mediumScreen ? 'row' : 'column'} justifyContent={mediumScreen ? 'space-between' : 'flex-end'} sx={{ width: 1, my: mediumScreen ? 1 : 0 }} spacing={1}>
      <FlexBox sx={{ justifyContent: 'center' }}>
        {useDateTimeRangeSelectComponent
          ? <DateTimeRangeSelect
            defaultValue={{
              startDate: DateTime.now().minus({ hour: 1 }),
              endDate: DateTime.now(),
            }}
            maxDateRange={1}
            onDateChange={(startDate, endDate) => {
              onDateRangeChanged(startDate.toJSDate(), endDate.toJSDate());
            }}
            quickSelectOptions={[
              {
                label: t('common:component.date-time-range.labels.last-hour', { count: T_ONE }),
                value: DateTime.now().minus({ hours: 1 }),
              },
              {
                label: t('common:component.date-time-range.labels.last-hour', { count: 4 }),
                value: DateTime.now().minus({ hours: 4 }),
              },
              {
                label: t('common:component.date-time-range.labels.last-hour', { count: 8 }),
                value: DateTime.now().minus({ hours: 8 }),
              },
            ]}
            smallScreen={smallScreen}
            value={dateRange
              ? {
                startDate: dateRange.startTime,
                endDate: dateRange.endTime,
              }
              : undefined
            }
          />
          : <RangeSelect
            data-testid='camera-content-range-select'
            isDateTimePickerOpen={isDateTimePickerOpen}
            onDateRangeChanged={onDateRangeChanged}
            openDateTimePicker={() => setIsDateTimePickerOpen(true)}
            rangeSelection={rangeSelection}
            rangeSelectOptions={rangeSelectOptions}
            savedRange={savedRange}
            setRangeSelection={setRangeSelection}
          />
        }
      </FlexBox>
      <RangeControl
        onDateRangeChanged={onDateRangeChanged}
        renderControl={rangeSelection === RangeSelectValues.PICK_CUSTOM_RANGE.key}
        savedRange={savedRange}
        setSavedRange={setSavedRange}
      />
      <DateTimeRangePickerProvider>
        <DateTimeRangePicker
          data-testid='camera-content-picker'
          closeDateTimePicker={() => setIsDateTimePickerOpen(false)}
          defaultEndTime={undefined}
          defaultStartTime={undefined}
          isDateTimePickerOpen={isDateTimePickerOpen}
          maxDateRange={1}
          onDateRangeChanged={onDateRangeChanged}
          savedRange={savedRange}
          setRangeSelection={setRangeSelection}
          setSavedRange={setSavedRange}
        />
      </DateTimeRangePickerProvider>
    </Stack>
  );
};

export default CameraDateRange;
