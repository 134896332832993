import { PageIcon, useMaxScreen, useSidebarPinContext } from '@eagle/react-common';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface LayoutProps {
  actions: ReactNode;
  children: ReactNode;
  isLoading: boolean;
  pageIcon?: JSX.Element;
  pageSubtitle: ReactNode;
  pageTitle: ReactNode;
  requestAvailable: JSX.Element;
}

const CameraDesktop: FC<LayoutProps> = ({ actions, children, isLoading, pageIcon, pageSubtitle, pageTitle, requestAvailable }) => {
  const { t } = useTranslation(['track']);
  const { isPinned } = useSidebarPinContext();
  const mediumScreen = useMaxScreen('md');
  const largeScreen = useMaxScreen('lg');
  const breakpoint = isPinned ? largeScreen : mediumScreen;

  return (
    <Box id="detail-page" display="flex" flexDirection="column" width={1}>
      <Stack id="page-header" className="detail-header" data-testid="camera-content-header">
        <Stack direction={breakpoint ? 'column' : 'row'} spacing={1} justifyContent="space-between" pt={1}>
          <Stack justifyContent="space-between" spacing={1}>
            <Stack alignItems="center" direction="row" spacing={1}>
              {pageIcon && <PageIcon icon={pageIcon} />}
              {pageTitle}
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }}>
              {pageSubtitle}
            </Stack>
          </Stack>
          <Box>
            <Stack direction="row" justifyContent="flex-end">
              {actions}
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack
        id="content"
        className="detail-content"
        display="flex"
        direction="row"
        overflow="auto"
        spacing={1}
        sx={{
          filter: isLoading ? 'blur(1px)' : '',
          opacity: isLoading ? 0.66 : 1,
        }} >
        {children}
      </Stack>
      <Stack spacing={2}>
        <Typography color="text.secondary" variant="caption">
          {t('track:page.camera-content.request-media.hint.info')}
        </Typography>
        <Box>
          {requestAvailable}
        </Box>
      </Stack>
    </Box>
  );
};

export default CameraDesktop;
