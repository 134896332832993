import { DateRange, getFormattedDate, getFormattedTime } from '@eagle/react-common';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface LayoutProps extends PropsWithChildren {
  dateRange: DateRange;
  handleModalClose: () => void;
  isLoading: boolean;
  pageSubtitle: ReactNode;
  requestAvailable: JSX.Element;
  requestModalOpen: boolean;
}

export const CameraMobile: FC<LayoutProps> = ({
  children,
  dateRange,
  handleModalClose,
  isLoading,
  pageSubtitle,
  requestAvailable,
  requestModalOpen,
}) => {
  const { t } = useTranslation(['common', 'track']);
  const { startTime, endTime } = dateRange;

  return (
    <Box display="flex" flexDirection="column" width={1}>
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between', px: 3, pt: 2 }}>
        {pageSubtitle}
        <Grid data-chromatic="ignore" item xs={6} sx={{ textAlign: 'right' }}>
          <Grid><Typography sx={{ fontSize: '0.813rem' }} variant="body2">{getFormattedTime(startTime, DateTime.TIME_WITH_SECONDS)} - {getFormattedTime(endTime, DateTime.TIME_WITH_SECONDS)}</Typography></Grid>
          <Grid><Typography sx={{ fontSize: '0.813rem' }} variant="body2">{getFormattedDate(startTime)}</Typography></Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Stack
        className="detail-content"
        direction="column"
        display="flex"
        overflow="auto"
        spacing={1}
        sx={{
          filter: isLoading ? 'blur(1px)' : '',
          height: '100%',
          opacity: isLoading ? 0.66 : 1,
        }}
      >
        {children}
      </Stack>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleModalClose}
        open={requestModalOpen}
        PaperProps={{
          style: { margin: '0%' },
        }}
        style={{ width: '100%', borderRadius: '0' }}
      >
        <DialogTitle>{t('track:page.camera-content.request-media.action')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('track:page.camera-content.request-media.hint.info')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>{t('common:common.action.cancel')}</Button>
          <Button component="div" onClick={handleModalClose}>
            {requestAvailable}
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default CameraMobile;
