/* eslint-disable react-hooks/rules-of-hooks */
import { LastThingEvent, Thing, ThingType } from '@eagle/core-data-types';
import { CacheDataTypes, DateTimeRange, ErrorMessage, ErrorTitle, FetchOneOfAll, FormatTimestamp, getMaybePropertyValue, getThingEventServerTime, LastContact, ListPaperColumn, MiddleSpinner, NotFound, testid, useCustomRoutes, useSmallScreen, useTitle } from '@eagle/react-common';
import { Alert, Box, Button, Grid, Link, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import CameraDateRange from './camera-date-range';
import LayoutDesktop from './camera-desktop';
import LayoutMobile from './camera-mobile';
import { ViewProps } from './camera-view.props';
import { MobileFilterDrawer } from './mobile-filters-drawer';

interface ThingDisplayProps {
  data: Thing;
  mobile: boolean;
  lastThingEvent?: LastThingEvent;
  lastThingError?: Error;
}

const ThingDisplay: FC<ThingDisplayProps> = ({ data, mobile, lastThingEvent, lastThingError }) => {
  const { thing } = useCustomRoutes();
  const { t } = useTranslation(['common', 'terms', 'track', 'manage']);
  const DisplayElement: FC<{ label: string; display: JSX.Element | string; displayHref?: string; displayContact?: boolean }> = ({ display, displayContact, displayHref, label }) => {
    if (mobile) {
      return (
        <Grid
          alignItems="flex-start"
          flexDirection="column"
          item
          xs={6}
        >
          <Grid item><Typography sx={{ fontSize: '0.75rem' }} variant="body2">{label}</Typography></Grid>
          <Grid item>
            {
              displayHref
                ? <Link
                  color="primary"
                  data-testid={testid`header-thing-link-${data._id}`}
                  href={useHref(displayHref)}
                  sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex', mr: 0 }}
                  underline="hover"
                >
                  <Typography variant="h6" sx={{ mr: 0, fontSize: '1rem' }}>{display}</Typography>
                </Link>
                : <Typography variant="h6" sx={{ mr: 0, fontSize: '1rem' }}>{display}</Typography>
            }
          </Grid>
        </Grid>
      );
    }

    return (
      <Stack direction='column'>
        <Stack alignItems="flex-start" direction="row" spacing={1}>
          <ListPaperColumn
            boxSx={{ height: '100%' }}
            label={`${label}: `}
            sx={{
              cursor: 'default',
              overflow: 'inherit',
              '.MuiBox-root': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            {displayHref
              ? <Box sx={{ '&': { alignItems: 'center', display: 'flex' } }}>
                <Link
                  color="primary"
                  data-testid={testid`header-thing-link-${data._id}`}
                  href={useHref(displayHref)}
                  sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}
                  underline="hover"
                >
                  <Typography>{display}</Typography>
                </Link>
              </Box>
              : <Box sx={{ '&': { alignItems: 'center', display: 'flex' } }}>
                {displayContact
                  && <LastContact
                    lastThingError={lastThingError}
                    lastThingEvent={lastThingEvent}
                    sx={{ '& .MuiTooltip-popper': { marginTop: 0 }, mr: .5 }}
                    tooltipSx={{ marginTop: 0 }}
                  />
                }
                <Typography>{display}</Typography>
              </Box>
            }
          </ListPaperColumn>
        </Stack>
        <Box sx={{ mt: 1 }}>
          {displayContact
            && <Box sx={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
              <LastContact
                lastThingError={lastThingError}
                lastThingEvent={lastThingEvent}
                sx={{ mr: 1 }}
              />
              {lastThingEvent &&
                <Typography component='div' variant="caption">
                  {t('common:component.last-contact.labels.contact-time')} <FormatTimestamp format='relative' value={getThingEventServerTime(lastThingEvent)} />
                </Typography>
              }
            </Box>
          }
        </Box>
      </Stack >
    );
  };

  return (
    <FetchOneOfAll
      dataType={CacheDataTypes.THING_TYPE}
      id={data.thingTypeId}
      renderFactory={(thingType: ThingType) => {
        if (mobile) {
          return (
            <DisplayElement
              display={data.display}
              displayContact
              displayHref={`/${thing}/${data._id}`}
              label={thingType.display}
            />
          );
        }

        return (
          <Stack direction="row" spacing={2.5}>
            <DisplayElement
              display={data.display}
              displayContact
              displayHref={`/${thing}/${data._id}`}
              label={thingType.display}
            />
            {thingType.properties.order.slice(0, 2).map((property, i) => (
              <DisplayElement
                key={i}
                display={getMaybePropertyValue(data.properties[property]) ?? ''}
                label={thingType.properties.definitions[property].label}
              />
            ))}
          </Stack>
        );
      }}
    />
  );
};

export const DetailView = ({
  actions,
  data: [data, error, state],
  dateOpen,
  dateOptions,
  dateRange,
  disableBack,
  disableForward,
  filtersOpen,
  forwardLabel,
  handleBack,
  handleDateClose,
  handleFilterClose,
  handleForward,
  handleModalClose,
  isLoading,
  lastThingData: [lastThingEvent, lastThingError, lastThingStatus],
  onDateRangeChanged,
  pageIcon,
  renderPageContent,
  renderPageTitle,
  renderRequestButton,
  requestOpen,
  thingType,
}: ViewProps): JSX.Element => {
  const { t } = useTranslation(['common', 'track']);
  const smallScreen = useSmallScreen();

  const actionComponents = useMemo(
    () => actions?.length
      ? actions.map((action, i) => ({
        children: <Fragment key={i}>
          {action.info}
          <Button startIcon={action.icon} onClick={action.onClick} data-testid={action.testId ?? 'action-button'}>{action.label}</Button>
        </Fragment>,
        icon: action.icon,
        label: action.label,
      }))
      : [],
    [actions],
  );

  if (state === 'pending') {
    useTitle(t('common:common.labels.loading'));
    return (
      <Box sx={{ display: 'flex', height: '200px', justifyContent: 'center', width: '100vw' }}>
        <MiddleSpinner />
      </Box>
    );
  }

  if (error) {
    useTitle(<ErrorTitle error={error} />);
    return (
      <Alert severity="error" sx={{ margin: '1rem' }}>
        <ErrorMessage error={error} />
      </Alert>
    );
  }

  if (data === undefined) {
    useTitle(t('common:page.errors.not-found.title'));
    return (
      <Box flexGrow={1}>
        <NotFound />
      </Box>
    );
  }

  useTitle(renderPageTitle(data));

  if (smallScreen) {
    return (
      <LayoutMobile
        dateRange={dateRange}
        handleModalClose={handleModalClose}
        isLoading={isLoading}
        pageSubtitle={
          lastThingStatus === 'pending'
            ? t('common:common.labels.loading')
            : <ThingDisplay data={data} mobile={true} lastThingEvent={lastThingEvent} lastThingError={lastThingError} />}
        requestAvailable={renderRequestButton(data)}
        requestModalOpen={requestOpen}
      >
        <MobileFilterDrawer open={filtersOpen} onClose={handleFilterClose} thingType={thingType} />
        {renderPageContent(data, dateRange)}
        <Box sx={{ display: 'none' }}>
          <DateTimeRange
            dateOptions={[]}
            defaultEnd={dateRange.endTime}
            defaultStart={dateRange.startTime}
            disabled={isLoading}
            handleClose={handleDateClose}
            maxDateTime={DateTime.now().plus({ day: 1 })}
            minDateTime={DateTime.now().minus({ years: 10 })}
            onChange={onDateRangeChanged}
            onlyIcon={false}
            open={dateOpen}
            pickerWidth="100%"
            placeholderText={t('common:common.labels.date-range')}
            size="small"
          />
        </Box>
      </LayoutMobile>
    );
  }

  return (
    <LayoutDesktop
      actions={
        <Stack spacing={2}>
          <CameraDateRange
            dateOptions={dateOptions}
            disableBack={disableBack}
            disableForward={disableForward}
            dateRange={dateRange}
            forwardLabel={forwardLabel}
            handleForward={handleForward}
            handleBack={handleBack}
            isLoading={isLoading}
            minDateTime={DateTime.now().minus({ years: 10 })}
            maxDateTime={DateTime.now().plus({ day: 1 })}
            onDateRangeChanged={onDateRangeChanged}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">{actionComponents.map(({ children }) => children)}</Stack>
        </Stack>
      }
      isLoading={isLoading}
      pageIcon={pageIcon}
      pageSubtitle={lastThingStatus === 'pending'
        ? t('common:common.labels.loading')
        : <ThingDisplay data={data} mobile={false} lastThingEvent={lastThingEvent} lastThingError={lastThingError} />}
      pageTitle={<Typography variant="h4" data-testid="page-title">{renderPageTitle(data)}</Typography>}
      requestAvailable={renderRequestButton(data)}
    >
      {renderPageContent(data, dateRange)}
    </LayoutDesktop>
  );
};
